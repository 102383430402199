import React from "react";
import { Container, Row } from "react-bootstrap";
import { NavBar } from './NavBar.js';

import headerImg from "../assets/img/headerImg.svg";
import Type from "./Type";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
    return (
        <div className="banner__section">
            <div className="background__title background__title__banner">Welcome</div>
            <Container className="home-section section" id="home">

                <NavBar />
                <Container className="home-content home__container container grid">
                    <TrackVisibility  className="home-section">
                        {({ isVisible }) => 
                            <div className={isVisible ? "animate__animated animate__fadeIn topBannerDiv" : "topBannerDiv"}>
                                <Row>
                                    <div className="home-header">
                                        <h1 className="headingf home__greeting">
                                            Hi There!{" "}<span className="wave" role="img" aria-labelledby="wave">👋🏻</span>
                                        </h1>
                                        <h1 className="heading-namef home__name">
                                            I'm <strong className="main-name"> Clément Hurel </strong>
                                        </h1>
                                        <div className="typeDiv">
                                            <Type className="home_education"/>
                                        </div>
                                    </div>
                        
                                    <div className="home__handle">
                                        <img src={headerImg} alt="home pic" className="img-fluid home__img" />
                                    </div>
                                </Row>   
                            </div>     
                        }  
                    </TrackVisibility>
                </Container>
                </Container>
                <div className="spaceBreak">&nbsp;&nbsp;&nbsp;</div>

        </div>

    )
}