import React from "react";
import { Banner } from './Banner.js';
import { About } from './About.js';
import { Skills } from './Skills.js';
import { Services } from './Services.js';
import { Work } from './Work.js';
import { Contact } from './Contact.js';

export const Main = () => {
    return (
        <div className="main">
            <Banner />
            <About />
            <Skills/>
            <Work/>
            <Services/>
            <Contact/>
        </div>
    )
}
