import React from "react";


export const LeftSocial = () => {
    return (
        <div className="home__social">
            <a href="https://www.linkedin.com/in/churel/" target="_blank" rel="noopener noreferrer" className="home__social-link">
                <i className='bx bxl-linkedin-square'></i>
            </a>
            <a href="https://github.com/hrlclem" target="_blank" rel="noopener noreferrer" className="home__social-link">
                <i className='bx bxl-github'></i>
            </a>
            <a href="mailto: business@clementhurel.fr" target="_blank" rel="noopener noreferrer" className="home__social-link">
                <i className='bx bx-envelope' ></i>
            </a>
        </div>
    )
}