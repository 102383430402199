import React, { useState, useEffect } from "react";
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-scroll";

export const NavBar = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () =>{
            if(window.scrollY> 50){
                setScrolled(true);
            } else{
                setScrolled(false);
            }
        }
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [])


    return (
        <Navbar className={scrolled ? "scrolled" : ""}>
            <header className='header' id='header'>
                <nav className='nav header-container' href="/">
                    <a href="/" className="nav__logo">[c]</a>
                    <div className='nav__header__menu'>
                        <div className='nav__header__subMenu'>
                            <ul className='nav__header__list'>
                                <li className="nav__header__item">
                                    <Link activeClass="active-top" smooth spy offset={-160} to="home" className="nav__header__link">
                                        Home
                                    </Link>
                                </li>
                                <li className="nav__header__item">
                                    <Link activeClass="active-top" smooth spy offset={-30} to="about" className="nav__header__link">
                                        About
                                    </Link>
                                </li>
                                <li className="nav__header__item">
                                    <Link activeClass="active-top" smooth spy offset={-30} to="skills" className="nav__header__link">
                                        Skills
                                    </Link>
                                </li>
                                <li className="nav__header__item">
                                    <Link activeClass="active-top" smooth spy offset={-30} to="work" className="nav__header__link">
                                        Work
                                    </Link>
                                </li>
                                <li className="nav__header__item">
                                    <Link activeClass="active-top" smooth spy offset={-30} to="services" className="nav__header__link">
                                        Services                             
                                    </Link>
                                </li>
                                <li className="nav__header__item">
                                    <Link activeClass="active-top" smooth spy offset={-30} to="contact" className="nav__header__link">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='nav__menu'>
                        <div className='nav__subMenu'>
                            <ul className='nav__list'>
                                <li className="nav__item">
                                    <Link activeClass="active" smooth spy offset={-160} to="home" className="nav__link">
                                        <i className='bx bx-home-alt'></i>
                                    </Link>
                                </li>
                                <li className="nav__item">
                                    <Link activeClass="active" smooth spy offset={-30} to="about" className="nav__link">
                                        <i className='bx bx-user' ></i>                              
                                    </Link>
                                </li>
                                <li className="nav__item">
                                    <Link activeClass="active" smooth spy offset={-30} to="skills" className="nav__link">
                                        <i className='bx bx-book-bookmark' ></i>                                
                                    </Link>
                                </li>
                                <li className="nav__item">
                                    <Link activeClass="active" smooth spy offset={-30} to="work" className="nav__link">
                                        <i className='bx bx-customize' ></i>
                                    </Link>
                                </li>
                                <li className="nav__item">
                                    <Link activeClass="active" smooth spy offset={-30} to="services" className="nav__link">
                                        <i className='bx bx-pie-chart-alt-2' ></i>                                
                                    </Link>
                                </li>
                                <li className="nav__item">
                                    <Link activeClass="active" smooth spy offset={-30} to="contact" className="nav__link">
                                        <i className='bx bx-chat'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <i className='bx bx-moon change-theme moon-button' id="'theme-button" ></i> */}
                </nav>
            </header>
      </Navbar>
    )
}
