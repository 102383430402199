import  {useEffect, useState} from "react";
import React from "react";
import projects from '../assets/js/projects.js';
import ProjectCard from './ProjectCard.js';

export const Work = () => {

    const [projectList] = useState(projects);
    const [filtered, setFiltered] = useState([]);
    const [activeType, setActiveType] = useState('all');

    useEffect(()=> {
        if(activeType === 'all'){
            setFiltered(projectList);
            return;
        }
        const filter = projectList.filter((project) =>
                project.type.includes(activeType)
            );
            setFiltered(filter);
        }, [activeType]);
    
    return (
        <section className="work section" id="work">
            <div className="background__title">Projects</div>
            <div className="work__section">
                <span className="section__subtitle">My Portfolio</span>
                <h2 className="section__title">Recent Projects</h2>

                <div className="work__filters">
                    <span 
                        className={`work__items ${activeType === 'all' ? " active-work" : ""}`}
                        data-filter='all' 
                        onClick={() => setActiveType('all')}>
                            All
                    </span>
                    {/* <span 
                        className={`work__items ${activeType === 'bootcamp' ? " active-work" : ""}`}
                        data-filter='.bootcamp' 
                        onClick={() => setActiveType('bootcamp')}>
                            Bootcamp
                    </span> */}
                    <span 
                        className={`work__items ${activeType === 'personal' ? " active-work" : ""}`}
                        data-filter='.personal' 
                        onClick={() => setActiveType('personal')}>
                            Personal
                    </span>
                    <span 
                        className={`work__items ${activeType === 'freelance' ? " active-work" : ""}`}
                        data-filter='.freelance' 
                        onClick={() => setActiveType('freelance')}>
                            Freelance
                    </span>
                    {/* <span 
                        className={`work__items ${activeType === 'mobile' ? " active-work" : ""}`}
                        data-filter='.mobile' 
                        onClick={() => setActiveType('mobile')}>
                            Mobile
                    </span> */}
                </div>

                <div className="work__container container grid">
                    {filtered.map((project) =>{
                        return <ProjectCard key={project.id} project={project}/>
                    })}
                </div>
            </div>

            <div className="spaceBreak">&nbsp;&nbsp;&nbsp;</div>

        </section>
    )
}