import mamoru from "../img/gif/mamoru.gif";
import embassy from "../img/gif/embassy.gif";
import tictactoe from "../img/gif/tictactoe.gif";
import booklibrary from "../img/gif/book-library.gif";
import weatherapp from "../img/gif/weather-app.gif";
import macohattori from "../img/gif/macohattori.gif";
import etchandsketch from "../img/gif/etchandsketch.png";
import memberclub from "../img/gif/member-club.gif";
import beststore from "../img/gif/beststore.gif";
import todo from "../img/gif/todo.gif";
import tokenclub from "../img/gif/tokenclub.gif";
import inventory from "../img/gif/inventory.gif";
import merveilleux from "../img/gif/merveilleux.gif";

import js from "../img/tech-icon/js.svg";
import bootstrap from "../img/tech-icon/bootstrap.svg";
import css from "../img/tech-icon/css.svg";
// import docker from "../img/tech-icon/docker.svg";
import html from "../img/tech-icon/html.svg";
import mongodb from "../img/tech-icon/mongodb.svg";
import nodejs from "../img/tech-icon/nodejs.svg";
import express from "../img/tech-icon/express.svg";
import php from "../img/tech-icon/php.svg";
import react from "../img/tech-icon/react.svg";



const projects = [
    {   
        id:1,
        type: "personal",
        title:"Mamoru",
        time:"Q3 2022",
        description:"Volunteer in a start-up to promote sustainability",
        tech:[js,html,css, react],
        link: "https://app.mamoru.earth/en/",
        github:"",
        imgUrl: mamoru,
    },  
    {
        id:11,
        type: "personal",
        title:"Blog token",
        time:"Q3 2022",
        description:"A blog website to practice node.js, JSON web token and authentification",
        tech:[js,html,css, nodejs, express, mongodb, bootstrap],
        link: "https://blog-token-auth-vercel.vercel.app",
        github:"https://github.com/hrlclem/blog-token-auth",
        imgUrl: tokenclub,
    },   
    {
        id:5,
        type: "personal",
        title:"Member club",
        time:"Q3 2022",
        description:"A restricted website to practice log-in, sign-up and routing.",
        tech:[js,html,css, nodejs,express, mongodb],
        link: "https://members-only-zeta.vercel.app",
        github:"https://github.com/hrlclem/members-only",
        imgUrl: memberclub,
    },
    {
        id:7,
        type: "freelance",
        title:"Maco Hattori",
        time:"Q3 2021",
        description:"An E-commerce website for a Japanese Artist",
        tech:[js,html,css, php],
        link: "https://www.macohattori.com",
        github:"",
        imgUrl: macohattori,
    },     
    {
        id:6,
        type: "personal",
        title:"E-commerce",
        time:"Q3 2022",
        description:"A react app to practice API fetch and components.",
        tech:[js,html,css, react, ],
        link: "https://github.com/hrlclem/shopping-cart",
        github:"https://github.com/hrlclem/shopping-cart",
        imgUrl: beststore,
    },    
    {
        id:13,
        type: "personal",
        title:"Café shop",
        time:"Q1 2022",
        description:"A basic café website to master basics",
        tech:[js,html,css],
        link: "https://github.com/hrlclem/merveilleux-restaurant",
        github:"https://github.com/hrlclem/merveilleux-restaurant",
        imgUrl: merveilleux,
    },    
    {
        id:8,
        type: "freelance",
        title:"Embassy Cafe",
        time:"Q4 2022",
        description:"A restaurant website with booking feature",
        tech:[js,html,css, php],
        link: "http://booking.embassy-cafe.jp",
        github:"",
        imgUrl: embassy,
    },  
    {
        id:9,
        type: "personal",
        title:"Weather App",
        time:"Q3 2022",
        description:"A weather app to practice fetch APIs",
        tech:[js,html,css],
        link: "https://weather-app-hrlclem.netlify.app",
        github:"https://github.com/hrlclem/weather-app",
        imgUrl: weatherapp,
    },
    {
        id:4,
        type: "personal",
        title:"Book Library",
        time:"Q2 2022",
        description:"A book library app that allows you to add all the books you read or want to read.",
        tech:[js,html,css],
        link: "https://book-library-hrl.netlify.app",
        github:"https://github.com/hrlclem/book-library",
        imgUrl: booklibrary,
    },        
    {
        id:10,
        type: "personal",
        title:"To-do list",
        time:"Q2 2022",
        description:"A to-do list to master ES6 JS, modules and other implementations",
        tech:[js,html,css],
        link: "https://github.com/hrlclem/to-do-list",
        github:"https://github.com/hrlclem/to-do-list",
        imgUrl: todo,
    },

    {
        id:12,
        type: "personal",
        title:"Inventory app",
        time:"Q3 2022",
        description:"An inventory application aimed for logistics in order to practice backend mainly",
        tech:[js,html,css, nodejs, express, mongodb],
        link: "https://github.com/hrlclem/MERN-inventory-app",
        github:"https://github.com/hrlclem/MERN-inventory-app",
        imgUrl: inventory,
    },
    {
        id:2,
        type: "personal",
        title:"Tic Tac Toe",
        time:"Q1 2022",
        description:"A Tic-Tac-Toe game to master Javascript functions",
        tech:[js,html,css],
        link: "https://tic-tac-toe-hrl.netlify.app",
        github:"https://github.com/hrlclem/tic-tac-toe",
        imgUrl: tictactoe,
    },
    {
        id:3,
        type: "personal",
        title:"Etch and Sketch",
        time:"Q1 2022",
        description:"An Etch and Sketch game to practice coding skills.",
        tech:[js,html,css],
        link: "https://etch-and-sketch-hrl.netlify.app",
        github:"https://github.com/hrlclem/etch-a-sketch",
        imgUrl: etchandsketch,
    },
]

export default projects;