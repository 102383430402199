import React from "react";


export const Skills = () => {
    return (
        <div className="skills section" id="skills">
            <div className="background__title">Knowledge</div>

            <span className="section__subtitle">My abilities</span>
            <h2 className="section__title">My skills</h2>

            <div className="skills__container container grid">
                <div className="skills__content">
                    <h3 className="skills__title"> Front-End Developer</h3>
                    <div className="skills__box">
                        <div className="skills__group">
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">HTML, CSS</h3>
                                    <div className="skills__level">Advanced</div>
                                </div>
                            </div>
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">Javascript</h3>
                                    <div className="skills__level">Advanced</div>
                                </div>
                            </div>
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">React.js</h3>
                                    <div className="skills__level">Intermediate</div>
                                </div>
                            </div>
                        </div>
                        <div className="skills__group">
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">Bootstrap</h3>
                                    <div className="skills__level">Intermediate</div>
                                </div>
                            </div>
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">TypeScript</h3>
                                    <div className="skills__level">Beginner</div>
                                </div>
                            </div>
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">Python</h3>
                                    <div className="skills__level">Beginner</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="skills__content">
                    <h3 className="skills__title"> Back-End Developer</h3>
                    <div className="skills__box">
                        <div className="skills__group">
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">Node.Js</h3>
                                    <div className="skills__level">Advanced</div>
                                </div>
                            </div>
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">Express</h3>
                                    <div className="skills__level">Intermediate</div>
                                </div>
                            </div>
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">Redux</h3>
                                    <div className="skills__level">Beginner</div>
                                </div>
                            </div>
                        </div>
                        <div className="skills__group">
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">PHP</h3>
                                    <div className="skills__level">Intermediate</div>
                                </div>
                            </div>
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">MongoDB</h3>
                                    <div className="skills__level">Intermediate</div>
                                </div>
                            </div>
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">MySQL</h3>
                                    <div className="skills__level">Intermediate</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="skills__content">
                    <h3 className="skills__title"> Tools</h3>
                    <div className="skills__box">
                        <div className="skills__group">
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">Docker</h3>
                                    <div className="skills__level">Intermediate</div>
                                </div>
                            </div>
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">Git</h3>
                                    <div className="skills__level">Advanced</div>
                                </div>
                            </div>
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">AWS</h3>
                                    <div className="skills__level">Intermediate</div>
                                </div>
                            </div>
                        </div>
                        <div className="skills__group">
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">Spark</h3>
                                    <div className="skills__level">Beginner</div>
                                </div>
                            </div>
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">Hadoop</h3>
                                    <div className="skills__level">Beginner</div>
                                </div>
                            </div>
                            <div className="skills__data">
                                <i className='bx bxs-badge-check'></i>
                                <div>
                                    <h3 className="skills__name">Firebase</h3>
                                    <div className="skills__level">Intermediate</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spaceBreak">&nbsp;&nbsp;&nbsp;</div>

        </div>
    )
}