import React from "react";
import {Footer} from "./Footer.js"

export const Contact = () => {
    return (
        <div className="contact section contact__section" id="contact">
            <div className="background__title">Let's talk</div>

            <span className="section__subtitle"> Get in touch</span>
            <h2 className="section__title">Contact Me</h2>
            <div className="contact__container container grid">
                <div className="contact__content">
                    <h3 className="contact__title">Let's start something together</h3>
                    <div className="contact__info">
                        <div className="contact__card">
                            <i className='bx bx-mail-send contact__card-icon' ></i>
                            <h3 className="contact__card-title">Email</h3>
                            <a href="mailto: business@clementhurel.fr" className="contact__card-data">business@clementhurel.fr</a>

                            <a href="mailto: business@clementhurel.fr" target="_blank" rel="noopener noreferrer" className="contact__button">
                                Write me <i className='bx bx-chevron-right contact__button-icon'></i>
                            </a>
                        </div>
                        <div className="contact__card">
                            <i className='bx bx-phone-call contact__card-icon' ></i>
                            <h3 className="contact__card-title">Phone</h3>
                            <a href="tel:+8115852034" className="contact__card-data">+81 70 1585 2034</a>
                            <a href="tel:+8115852034" target="_blank" rel="noopener noreferrer" className="contact__button">
                                Let's chat <i className='bx bx-chevron-right contact__button-icon'></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}