import './App.css';
import { Main } from './components/Main.js';

import { LeftSocial } from './components/LeftSocial.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";

function App() {
  return (
    <div className="App">
      <div className="body">
        <Main/>
        <LeftSocial/>
      </div>
    </div>
  );
}

export default App;
