import React from "react";
import githubLogo from "../assets/img/tech-icon/github.svg";

function ProjectCard({project}) {
  return (
      <div className="work__card mix freelance">
        <img src={project.imgUrl} alt="" className="work__img"></img>
        <div className="projectCardLow">
                <div className="work__header">
                  <h3 className="work__title">{project.title}</h3>
                  <div className='work__git'>
                    {project.github && 
                      <a href={project.github} target="_blank" rel="noopener noreferrer">
                        <img src={githubLogo} alt="" ></img>
                      </a>
                    }
                  </div>
                </div>
                <span className="work__description">{project.description}</span>
                <div className="work__techStack">
                  {project.tech.map((logo, i) =>{
                            return <img key={i} src={logo} alt="" className="tech__icon"></img>
                        })}
                </div>
                <div className="bottomProjectCard">
                  <a href={project.link} target="_blank" rel="noopener noreferrer" className="work__button">
                      Demo <i className='bx bx-chevron-right work_icon'></i>
                  </a>
                  <div className="work__time">{project.time}</div>
                </div>
        </div>
      </div>
  )
}

export default ProjectCard;