import React from "react";


export const Footer = () => {
    return (
        <div className="footer section" id="footer">
            <div className="footer__container container">
                <h1 className="footer__title">Clément Hurel</h1>
                <h1 className="footer__subtitle">Full-Stack Engineer</h1>
                <ul className="footer__list">
                    <li>
                        <a href="#about" offset={-60} className="footer__link">About</a>
                    </li>
                    <li>
                        <a href="#skills" offset={-60} className="footer__link">Skills</a>
                    </li>
                    <li>
                        <a href="#services" offset={-60} className="footer__link">Services</a>
                    </li>
                    <li>
                        <a href="#work" offset={-60} className="footer__link">Work</a>
                    </li>
                </ul>
                <ul className="footer__list">
                    <li>
                        <a href="#home" className="footer__link">
                        <i class='bx bx-chevron-up'></i>
                        <strong>     Back to top     </strong>
                        <i class='bx bx-chevron-up'></i>
                        </a>
                    </li>
                </ul>
                <ul className='footer__social'>
                        <a href="https://www.linkedin.com/in/churel/" target="_blank"  rel="noopener noreferrer" className="footer__social-link">
                            <i className='bx bxl-linkedin-square'></i>
                        </a>
                        <a href="https://github.com/hrlclem" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                            <i className='bx bxl-github'></i>
                        </a>                        
                        <a href="mailto: business@clementhurel.fr" target="_blank" rel="noopener noreferrer" className="footer__social-link">
                            <i className='bx bx-envelope' ></i>
                        </a>
                </ul>
                <span className="footer__copy">
                    &#169; Clément Hurel. All rights reserved, 2022
                </span>
            </div>
        </div>
    )
}