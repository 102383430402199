import React from "react";
import aboutImg from "../assets/img/about-img.jpg";

export const About = () => {
    return (
        <div className="about section wholeAboutBanner" id="about">
            <div className="background__title">Overview</div>
            <span className="section__subtitle">My Intro</span>
            <h2 className="section__title">About Me</h2>
            <div className="about__container container grid">
                <div className="aboutBanner">
                    <div className="firstDiv">
                        <img src={aboutImg} alt="" className="about__img leftAboutBanner"/>
                    </div>
                    <div className="secondDiv">
                        <p className="about__description">
                                My name is Clément, I am a French guy who enjoys coding on my free time.
                                My passion for programming started in 2018 when I was doing <strong>Big
                                Data Analysis</strong> during my <strong>Master's degree</strong>. 

                            </p>
                            <p className="about__description">
                                Last year, after one year in <strong>Brazil</strong> and three in <strong>Japan</strong>,  I discovered web-development and <strong>loved building full-stack apps</strong>: for clients, 
                                start-ups and myself.                            
                            </p>
                            <p className="about__description">
                                Today, I am a <strong>full-stack engineer</strong> eager to learn everyday and willing to take new challenges!
                            </p>
                            <a href="mailto: business@clementhurel.fr" className="button aboutContactBtn">Contact Me</a>
                    </div>
                </div>

                <div className="about__data">
                    <div className="about__info">
                        <div className="about__box">
                            <i className='bx bx-award about__icon'></i>
                            <h3 className="about__title">Experiences</h3>
                            <span className="about__subtitle"><strong>1 years</strong> in Banking</span>
                            <br></br>
                            <span className="about__subtitle"><strong>1 year</strong> as PM in IT</span>
                            <br></br>
                            <span className="about__subtitle"><strong>2 years</strong> as Freelancer</span>
                        </div>
                        <div className="about__box">
                            <i className='bx bx-briefcase-alt about__icon'></i>                            
                            <h3 className="about__title">Education</h3>
                            <span className="about__subtitle"><strong>Harvard</strong> CS50 (2022)</span><br></br>
                            <span className="about__subtitle"><strong>Master</strong> of Business (2018)</span><br></br>
                            <span className="about__subtitle"><strong>Master</strong> of Data Analysis (2018)</span><br></br>

                        </div>
                        <div className="about__box">
                            <i className='bx bx-paper-plane about__icon' ></i>                            
                            <h3 className="about__title">Languages</h3>
                            <span className="about__subtitle"><strong>French - English</strong></span><br></br>
                            <span className="about__subtitle"><strong>Portuguese - Japanese N3</strong></span><br></br>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spaceBreak">&nbsp;&nbsp;&nbsp;</div>

        </div>
    )
}