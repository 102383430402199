import React from "react";
import {useState} from 'react';

export const Services = () => {

    const [openModal1, setOpenModal1] = useState('');
    const [openModal2, setOpenModal2] = useState('');
    const [openModal3, setOpenModal3] = useState('');

    const handleModal1 = event => {
        if (openModal1 === ''){
            setOpenModal1('active-modal')
        } else{
            setOpenModal1('')
        }
    }
    const handleModal2 = event => {
        if (openModal2 === ''){
            setOpenModal2('active-modal')
        } else{
            setOpenModal2('')
        }
    }
    const handleModal3 = event => {
        if (openModal3 === ''){
            setOpenModal3('active-modal')
        } else{
            setOpenModal3('')
        }
    }


    return (
        <section className="services section"  id="services">
            <div className="background__title">Services</div>
            <span className="section__subtitle">My Services</span>
            <h2 className="section__title">What I offer</h2>

            <div className="services__container container grid">
                <div className="services__card" onClick={handleModal1}>
                    <h3 className="services__title">Website <br></br>Development</h3>
                    <span className="services__button">
                        See More 
                        <i className='bx bx-chevron-right services__icon'></i>
                    </span>
                    <div className={`services__modal ${openModal1}`}>
                        <div className="services__modal-content">
                            <i className='bx bx-x services__modal-close' onClick={handleModal1}></i>
                            <h3 className="services__modal-title">Website Development</h3>
                            <p className="services__modal-description">
                                <br></br>
                                <strong>Website development</strong> experience for 
                                <br></br>
                                F&B, luxury and Art fields.
                                <br></br>
                                <br></br>
                                Providing <strong>quality work</strong> to clients and companies
                            </p>
                            <ul className="services__modal-list">
                                <li className="services__modal-item">
                                    <i className='bx bx-check services__modal-icon'></i>
                                    <p className="services__modal-info">
                                        Full-stack application
                                    </p>
                                </li>
                                <li className="services__modal-item">
                                    <i className='bx bx-check services__modal-icon'></i>
                                    <p className="services__modal-info">
                                        Business vitrine
                                    </p>
                                </li>
                                <li className="services__modal-item">
                                    <i className='bx bx-check services__modal-icon'></i>
                                    <p className="services__modal-info">
                                        Mobile application
                                    </p>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="services__card" onClick={handleModal2}>
                    <h3 className="services__title">Digital Marketing <br></br>consulting</h3>
                    <span className="services__button">
                        See More 
                        <i className='bx bx-chevron-right services__icon'></i>
                    </span>
                    <div className={`services__modal ${openModal2}`}>
                        <div className="services__modal-content">
                            <i className='bx bx-x services__modal-close' onClick={handleModal2}></i>
                            <h3 className="services__modal-title">Digital Marketing consulting</h3>
                            <p className="services__modal-description">
                                <br></br>
                                <strong>Specialized in Digital Marketing</strong> and experienced in paid ads on 
                                <br></br> 
                                <strong>Google, Instagram</strong> and <strong>Facebook</strong>.
                            </p>
                            <ul className="services__modal-list">
                                <li className="services__modal-item">
                                    <i className='bx bx-check services__modal-icon'></i>
                                    <p className="services__modal-info">
                                        Business Branding
                                    </p>
                                </li>
                                <li className="services__modal-item">
                                    <i className='bx bx-check services__modal-icon'></i>
                                    <p className="services__modal-info">
                                        Online and in-store 360° marketing strategies
                                    </p>
                                </li>
                                <li className="services__modal-item">
                                    <i className='bx bx-check services__modal-icon'></i>
                                    <p className="services__modal-info">
                                        Digital transformation
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="services__card" onClick={handleModal3}>
                    <h3 className="services__title">E-commerce management</h3>
                    <span className="services__button">
                        See More 
                        <i className='bx bx-chevron-right services__icon'></i>
                    </span>
                    <div className={`services__modal ${openModal3}`}>
                        <div className="services__modal-content">
                            <i className='bx bx-x services__modal-close' onClick={handleModal3}></i>
                            <h3 className="services__modal-title">E-commerce management</h3>
                            <p className="services__modal-description">
                                <br></br>
                                <strong>E-commerce website management</strong> with experience in the <strong>US and Japanese market</strong>
                                <br></br>
                                Industries include <strong>F&B and Entertainment</strong> mainly 
                            </p>
                            <ul className="services__modal-list">
                                <li className="services__modal-item">
                                    <i className='bx bx-check services__modal-icon'></i>
                                    <p className="services__modal-info">
                                        Shopify, Wordpress and Magento
                                    </p>
                                </li>
                                <li className="services__modal-item">
                                    <i className='bx bx-check services__modal-icon'></i>
                                    <p className="services__modal-info">
                                        Website branding
                                    </p>
                                </li>
                                <li className="services__modal-item">
                                    <i className='bx bx-check services__modal-icon'></i>
                                    <p className="services__modal-info">
                                        E-commerce optimization 
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spaceBreak">&nbsp;&nbsp;&nbsp;</div>

        </section>
    )
}